import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 국내 탑 여행 크리에이터 빠니보틀, 업계 최초 구독형 로밍서비스 ‘로밍패스’ 광고모델로 발탁",
  "| 핀다이렉트 ‘로밍패스’ 광고 캠페인, ‘통신환승’ 브랜드 캠페인 동시 전개",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/41/41_img1.png" style={{ width: "300px", margin: "auto" }} alt="" />
      </div>
      <div className="desc">&lt;(위) 핀다이렉트 로밍패스 모델 빠니보틀&gt;</div>
    </div>
    <p>
      <br />
      스테이지파이브(대표 서상원)는 핀다이렉트 로밍패스 광고모델로 국내 탑 여행 크리에이터인 빠니보틀을 발탁하고 브랜디드
      광고캠페인을 전개한다고 27일 밝혔다.
      <br />
      <br />
      로밍패스는 연간 멤버십 개념으로, 9,900원을 1회만 납부해도 전세계 대상 로밍상품을 횟수 제한 없이 50% 할인된 가격으로 이용할
      수 있는 구독형 데이터로밍 서비스이다. 핀다이렉트 앱을 사용하면 간편하게 가입할 수 있으며, 통신업계에서는 지난 6일
      스테이지파이브가 최초로 출시한 바 있다. △합리적인 가격(일본 기준 500MB 사용 시 혜택가 1,800원) △출국 전 사전 액티베이트
      △24시간 로밍전용 고객센터 운영 △핀다이렉트 앱 내에서 실시간 잔여데이터 확인 △추가 데이터 필요 시 앱 내 간편 연장 가능 등은
      핀다이렉트 로밍패스만의 강점이다.
      <br />
      <br />
      스테이지파이브는 구독자 180만 유튜버 빠니보틀과 함께 하는 이번 광고캠페인을 계기로 지난 6일 신규 출시한 로밍패스를
      본격적으로 알릴 계획이다. 빠니보틀이 메인 모델로 활약한 이번 광고 캠페인 콘텐츠는 빠니보틀의 실제 로밍 경험에서 착안하여
      리얼리즘을 강조한 에피소드 형식으로 기획되었다. 과거 좋좋소 웹드라마에 출연한 정필돈(강성훈 분), 백진상(김경민 분),
      이예영(진아진 분), 이길(이과장 분) 등도 함께 출연한다.
      <br />
      <br />
      스테이지파이브 관계자는 “금번 에피소드를 필두로 총 3개의 이어지는 콘텐츠를 준비 중” 이라며 “긍정적인 브랜드 이미지와 더불어
      웃음과 공감을 함께 드리기 위해 고심하였다”라고 말했다.
      <br />
      <br />
      빠니보틀과 함께한 ‘핀다이렉트 로밍패스’ 광고캠페인 영상은 핀다이렉트 유튜브를 시작으로 브랜드의 인스타그램 등 주요
      사회관계망서비스(SNS) 채널에서 확인할 수 있다.
      <br />
      <br />
      스테이지파이브는 광고 캠페인 런칭과 더불어 브랜드 캠페인 ‘통신환승’도 공개했다. 핀다이렉트 앱 내 통신환승 페이지를 통하면
      통신사를 환승하고자 하는 고객들이 핀다이렉트의 상품을 한 눈에 확인하고 선택할 수 있다. 단순 스펙이나 요금 등을 나열식으로
      구성하는 타사와 달리, 고객의 라이프스타일을 반영하여 Z시리즈로 제안하는 ‘통신환승’ 캠페인 페이지는 해당 요금제에 대한 고객
      이해를 한층 더 돕는다. 몰라서, 혹은 어려워서 그간 알뜰폰에 접근하지 못했던 기존 통신사 이용 고객들의 수요를 겨냥했다.
      <br />
      <br />
      약정 등의 이유로 당장 환승하지 못하는 고객을 위해 ‘환승알림’ 기능을 준비했다. 해당 기능을 통하면 고객이 원하는 시점에 맞춰
      개인별 적합한 요금제를 선별, 카카오톡으로 알림을 받아볼 수 있다. 해당 기능은 번호이동 시 스스로 자신의 통신 패턴을 파악 후,
      기업별/스펙별로 상이한 옵션을 일일이 비교해야 했던 고객의 불편함을 덜어주기 위해 고안되었다. 환승알림을 통해 개통한
      고객에게는 네이버페이 1만 포인트도 증정한다.
      <br />
      <br />
      스테이지파이브 관계자는 “자급제 단말을 찾는 고객이 점점 늘어나는 추세로, 이에 매칭되는 요금제를 찾는 수요도 함께 증가하고
      있다. 이러한 흐름에 맞추어 통신환승 캠페인을 기획하게 되었다.”며 “로밍패스 광고 캠페인과 통신환승 브랜드 캠페인 모두 철저히
      고객 관점에서 고민했고, 자신있게 선보인다. 타 통신사에서 겪지 못했던 만족감을 드리고 싶다.”고 밝혔다.
      <br />
    </p>
  </div>
)

const press41 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2023년 9월 27일 즉시배포 가능"
      pageInfo=""
      title="스테이지파이브, 핀다이렉트 로밍패스 모델로 ‘빠니보틀’ 발탁… 몰입도를 높인 드라마타이즈형 광고 영상 공개"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press41
